(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-list/assets/javascripts/last-row.js') >= 0) return;  svs.modules.push('/components/marketplace/game-list/assets/javascripts/last-row.js');

'use strict';

const {
  helpers
} = svs.components.lbUtils;
const {
  isFuture
} = dateFns;
const {
  gamesConstants
} = svs.components.marketplaceData;
const buyLastText = buyStop => helpers.formatDate.formatDate(buyStop, true, true);
const NoParticipationText = _ref => {
  let {
    buyStop,
    isSubmitted,
    gameStatus,
    canBuy,
    isCurrentUserAuthenticated
  } = _ref;
  if (canBuy && !isSubmitted && isFuture(buyStop)) {
    return "K\xF6p senast ".concat(buyLastText(buyStop));
  }
  if (!isCurrentUserAuthenticated) {
    return null;
  }
  const participationText = isSubmitted || gameStatus === gamesConstants.GAME_STATUS_DRAW_CLOSED ? 'deltog' : 'deltar';
  return "Du ".concat(participationText, " inte");
};
const getFractionsText = (currentUserNumberOfShares, isFinished) => {
  let hadOrHave = 'har';
  let shareOrShares = 'andel';
  if (isFinished) {
    hadOrHave = 'hade';
  }
  if (currentUserNumberOfShares > 1) {
    shareOrShares = 'andelar';
  }
  return "Du ".concat(hadOrHave, " ").concat(currentUserNumberOfShares, " ").concat(shareOrShares);
};
const LastRow = _ref2 => {
  let {
    isListing,
    gameStatus,
    maxFractions,
    buyStop,
    isFinished,
    isSubmitted,
    isSoldOut,
    isCurrentUserAuthenticated,
    isRetail,
    currentUserNumberOfShares
  } = _ref2;
  if (isListing && isRetail) {
    if (gameStatus === gamesConstants.GAME_STATUS_DRAW_CLOSED) {
      return null;
    }
    return "".concat(maxFractions, " andelar \u2022 ").concat(buyLastText(buyStop));
  }
  if (currentUserNumberOfShares > 0) {
    return getFractionsText(currentUserNumberOfShares, isFinished);
  }
  return React.createElement(NoParticipationText, {
    buyStop: buyStop,
    canBuy: !isSoldOut,
    gameStatus: gameStatus,
    isCurrentUserAuthenticated: isCurrentUserAuthenticated,
    isSubmitted: isSubmitted
  });
};
LastRow.defaultProps = {
  isCurrentUserAuthenticated: true
};
setGlobal('svs.components.marketplace.gameList.LastRow', LastRow);

 })(window);