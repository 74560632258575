(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-list/assets/javascripts/game-status.js') >= 0) return;  svs.modules.push('/components/marketplace/game-list/assets/javascripts/game-status.js');

'use strict';

const {
  format
} = svs.utils;
const {
  gamesConstants
} = svs.components.marketplaceData;
const {
  newLineToBreak
} = svs.components.lbUi;
const {
  applyNewLine
} = svs.components.marketplace.gameList.helpers;
const {
  LastRow
} = svs.components.marketplace.gameList;
const {
  useSelector
} = ReactRedux;
const {
  showFewSharesLeftRibbon
} = svs.components.marketplace.helpers.games;
const GameStatus = _ref => {
  let {
    game,
    gameStatus,
    groupId,
    isListing,
    isRetail
  } = _ref;
  const {
    activatedDrawId,
    buyStop,
    drawCloseTime,
    fractionPrice,
    currentUserNumberOfShares,
    isAllowedToSubmit,
    isSoldOut,
    isFinished,
    isSubmitted,
    maxFractions,
    ownerName
  } = game;
  const drawHasClosed = drawCloseTime <= Date.now();
  const hasPermissionToSubmit = isAllowedToSubmit;
  const myId = useSelector(_ref2 => {
    let {
      customerId
    } = _ref2;
    return customerId;
  });
  const isCurrentUserAuthenticated = Boolean(myId);
  const hasStatus = status => gameStatus === status;
  const classNamesWithMatch = [{
    className: 'fc-red',
    isMatching: (hasStatus(gamesConstants.GAME_STATUS_SUBMITTABLE) || hasStatus(gamesConstants.GAME_STATUS_NOT_ENOUGH_SOLD)) && !drawHasClosed && hasPermissionToSubmit
  }, {
    className: 'fc-navy',
    isMatching: true
  }];
  const resolveClassName = () => classNamesWithMatch.find(className => className.isMatching).className;
  const resolveStatusLabel = () => {
    if (isRetail) {
      const price = format.Currency(fractionPrice);
      return price ? "".concat(price, " kr") : '-';
    }
    const cost = format.Currency(fractionPrice * currentUserNumberOfShares);
    return parseInt(cost, 10) ? "".concat(cost, " kr") : '';
  };
  const getStatus = () => ({
    label: resolveStatusLabel(),
    className: resolveClassName()
  });
  const status = getStatus();
  const showFewSharesLeft = showFewSharesLeftRibbon(game, groupId);
  return React.createElement(React.Fragment, null, isRetail && React.createElement("div", {
    className: "game-status-wrapper"
  }, React.createElement("div", {
    className: "game-info-row1 f-200 overflow-threedots"
  }, isListing ? ownerName : "".concat(maxFractions, " andelar")), React.createElement("div", {
    className: "game-info-row1"
  })), React.createElement("div", {
    className: "game-status-wrapper ".concat(showFewSharesLeft && !isRetail ? 'few-shares-left-margin' : '')
  }, React.createElement("div", {
    className: "game-info-row2 f-400"
  }, React.createElement(LastRow, {
    activatedDrawId: activatedDrawId,
    buyStop: buyStop,
    currentUserNumberOfShares: currentUserNumberOfShares,
    gameStatus: gameStatus,
    groupId: groupId,
    isCurrentUserAuthenticated: isCurrentUserAuthenticated,
    isFinished: isFinished,
    isListing: isListing,
    isRetailer: isRetail,
    isSoldOut: isSoldOut,
    isSubmitted: isSubmitted,
    maxFractions: maxFractions
  })), React.createElement("div", {
    className: "game-info-row2 f-400 ".concat(status.className),
    "data-testid": "qa-game-status"
  }, newLineToBreak(applyNewLine(status.label, 6)))));
};
GameStatus.defaultProps = {
  gameStatus: ''
};
setGlobal('svs.components.marketplace.gameList.GameStatus', GameStatus);

 })(window);